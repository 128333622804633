<script>

import LogoSvg from "@/components/svg/LogoSvg.vue";
import LineSvg from "@/components/svg/LineSvg.vue";

export default {
  name: "CustomView",
  components: {LineSvg, LogoSvg}
}
</script>

<template>
  <div class="s-wrapper">
    <nav class="nav-wrapper">
      <div class="nav-left">
        <div class="logo-wrapper">
          <LogoSvg></LogoSvg>
          <LineSvg></LineSvg>
          <img src="@/assets/images/souniLogo.png" alt="">
        </div>
        <div class="nav-item-wrapper">
          <div class="nav-item"><a href="#">Mirror</a></div>
          <div class="nav-item"><a href="#">Tavern</a></div>
          <div class="nav-item"><a href="#">Card Bestiary</a></div>
          <div class="nav-item"><a href="#">Cliam</a></div>
          <div class="nav-item"><a href="#">Play</a></div>
          <div class="nav-item"><a href="#">Invite</a></div>
        </div>
      </div>
      <button class="nav-right claim-btn">Connect Wallet</button>
    </nav>
    <h1>Cliam</h1>
    <p class="tip-content">Whitelist Please participate in the whitelist activities in time. Members who meet the whitelist conditions will receive the Odyssey gift pack for free.

❤️There are only 100 Odyssey packs available this round, first come first served!.</p>
  </div>
  <img src="@/assets/images/top-bg.png" alt="" class="top-bg">
  <h3><span>STAKING OVERVIEW</span></h3>
  <div class="main-wrapper">
    <div class="main-left">
      <img src="@/assets/images/icon-son-locked.png" alt="">
      <p>SON whitelist</p>
      <p>-</p>
    </div>
    <div class="main-right">
      <div class="bg-wrapper">
      </div>
      <div class="btn-wrapper">
        <button class="claim-btn">Cliam $SON</button>
        <p>Learn more about Stake $SON to earn Points rules</p>
      </div>
    </div>

  </div>
</template>

<style lang="less">

@font-face {
  font-family: Norse;
  src: url('@/assets/fonts/norse.ttf');
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

body {
  background-color: #0e121d;
}

// 移动端
@media screen and (max-width: 768px) {
  .nav-item-wrapper, .nav-right {
    display: none;
  }

  .nav-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px 12px;
    box-sizing: border-box;

    .logo-wrapper {
      display: flex;
      align-items: center;

      svg:first-child {
        margin-right: 15px;
      }

      img {
        width: 87px;
        height: 43px;
      }
    }
  }

  img.top-bg {
    width: 100%;
    height: 429px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .s-wrapper {
    position: relative;
    z-index: 1;
    height: 429px;

    h1 {
      text-align: center;
      color: #fff;
      margin-top: 80px;
      font-weight: 700;
      line-height: 92px;
      font-size: 100px;
      font-family: Norse, sans-serif;
      margin-bottom: 32px;
    }

    .tip-content {
      height: 40px;
      padding: 12px 16px;
      margin: 0 40px 90px 40px;
      white-space: normal;
      color: #ffff;
      text-align: center;
      background-color: rgba(17, 21, 32, 0.6);
      border-radius: 38px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  h3 {
    text-align: center;
    height: 42px;
    margin: 8px 0 48px 0;
    box-sizing: border-box;
    position: relative;
    width: 100%;

    span {
      color: #4a639b;
      font-size: 28px;
      position: relative;
    }

  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .main-left {
      background-color: #191f2f;
      border-radius: 15px;
      border: 2.66667px solid rgb(37, 44, 70);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        max-width: 313px;
        width: 100%;
      }

      p {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        margin: 10px 0;

        &:last-child {
          color: rgb(179, 164, 246);
          font-size: 30px;
        }
      }
    }

    .main-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #191f2f;
      border-radius: 15px;
      border: 2.66667px solid rgb(37, 44, 70);
      margin-top: 8px;

      .bg-wrapper {
        width: 100%;
        height: 180px;
        margin-bottom: 28px;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/images/staking-overview-bg.55a8ab93.jpeg');
      }
      .btn-wrapper {
        flex: 1;
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        button.claim-btn {
          width: 60%;
          height: 23px;
          line-height: 23px;
          padding: 10px 20px;
          text-align: center;
          color: #fff;
          background-image: linear-gradient(95deg, rgb(212, 130, 255), rgb(120, 86, 205) 100.97%);
          border: 1px solid rgb(218, 152, 255);
          box-sizing: content-box;
          border-radius: 50px;
          cursor: pointer;
        }

        p {
          color: rgb(187, 187, 187);
          font-size: 14px;
          margin-top: 20px;
          position: absolute;
          bottom: 0;
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
}

// PC端
@media screen and (min-width: 768px) {
  .nav-wrapper {
    width: 100%;
    height: 90px;
    padding: 20px 40px;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 1640px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-left {
      display: flex;
      align-items: center;

      .logo-wrapper {
        display: flex;
        align-items: center;

        svg:first-child {
          margin-right: 15px;

        }

        svg:nth-child(2) {
          margin: 0 15px;
          padding: 2px 0;
        }

        img {
          width: 87px;
          height: 43px;
        }
      }

      .nav-item-wrapper {
        display: flex;
        align-items: center;

        .nav-item {
          margin: 0 15px;

          & > a {
            color: #fff;
            font-weight: bold;
          }
        }

        .nav-item:first-child > a {
          color: #fe3700;
        }

        .nav-item:nth-child(2) > a {
          color: #feaf00;
        }
      }
    }

    .nav-right {
      width: 125px;
      height: 33px;
      padding: 0 14px;
      border-radius: 52px;
      background-image: linear-gradient(95deg, rgb(212, 130, 255), rgb(120, 86, 205) 100.97%);
      border: 1px solid rgb(218, 152, 255);
      color: #fff;
      cursor: pointer;
    }
  }

  img.top-bg {
    width: 100%;
    height: 429px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .s-wrapper {
    position: relative;
    z-index: 1;
    height: 429px;

    h1 {
      text-align: center;
      color: #fff;
      margin-top: 80px;
      font-weight: 700;
      line-height: 92px;
      font-size: 100px;
      font-family: Norse, sans-serif;
      margin-bottom: 32px;
    }

    .tip-content {
      width: 520px;
      height: 25px;
      padding: 15px 35px;
      margin: 0 auto 90px auto;
      color: #ffff;
      text-align: center;
      background-color: rgba(17, 21, 32, 0.6);
      border-radius: 38px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  h3 {
    text-align: center;
    height: 42px;
    margin: 8px 0 48px 0;
    box-sizing: border-box;
    position: relative;
    width: 100%;

    span {
      color: #4a639b;
      font-size: 28px;
      position: relative;

      &::before, &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 380px;
        height: 1px;
        background: #1f263b;
        transform: translateY(-50%);
      }

      &:before {
        left: calc(-100% - 100px);
      }

      &:after {
        right: calc(-100% - 100px);
      }
    }
  }

  .main-wrapper {
    max-width: 1366px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .main-left {
      background-color: #191f2f;
      border-radius: 15px;
      border: 2.66667px solid rgb(37, 44, 70);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 8px;

      p {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        margin: 10px 0;

        &:last-child {
          color: rgb(179, 164, 246);
          font-size: 30px;
        }
      }
    }

    .main-right {
      background-color: #191f2f;
      border-radius: 15px;
      border: 2.66667px solid rgb(37, 44, 70);
      flex: 1;
      padding: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .bg-wrapper {
        height: 180px;
        margin-bottom: 28px;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/images/staking-overview-bg.55a8ab93.jpeg');
      }

      .btn-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        button.claim-btn {
          width: 257px;
          height: 23px;
          line-height: 23px;
          padding: 10px 20px;
          text-align: center;
          color: #fff;
          background-image: linear-gradient(95deg, rgb(212, 130, 255), rgb(120, 86, 205) 100.97%);
          border: 1px solid rgb(218, 152, 255);
          box-sizing: content-box;
          border-radius: 50px;
          cursor: pointer;
        }

        p {
          color: rgb(187, 187, 187);
          font-size: 14px;
          margin-top: 20px;
          position: absolute;
          bottom: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>