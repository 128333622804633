<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="100%" viewBox="0 0 2 36" fill="none">
    <path d="M1.20312 0.782227V35.7822" stroke="#9A9A9A"></path>
  </svg>
</template>

<style scoped lang="less">

</style>